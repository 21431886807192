const searchInput = document.getElementById('zendesk_search_searchterm');

if (searchInput) {
  searchInput.addEventListener('input', function() {
    if (this.value.length >= 3) {
      const formData = new FormData();
      formData.append('searchterm', this.value); 

      fetch('/faqs/suggestions', { 
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/javascript' 
        }
      })
      .then(response => {
        if (!response.ok) {
          // Do nothing
        }
        return response.text(); 
      })
      .then(jsCode => {
        eval(jsCode); 
      })
      .catch(error => {
        // Keep Schtum
      });
    } else {
      const suggestionBox = document.getElementById('zendesk_suggestions'); 
      if (suggestionBox) {
        suggestionBox.innerHTML = ''; 
      }
    }
  });
}
