export default () => {

  let textExpander = document.querySelector('[data-text-expander="container"]');

  if(textExpander && window.innerWidth > 991){
    const isOverflown = (el) => {
      return el.scrollHeight > el.clientHeight
    }

    let containers = document.querySelectorAll('[data-text-expander="container"]');
    let contArray = [...containers];
  
    contArray.forEach(cont => {
      let target = cont.querySelector('[data-text-expander="target"]');
      if(isOverflown(target)){
        let btn = cont.querySelector('[data-text-expander="btn"]');
        btn.classList.add('show')
        btn.addEventListener('click', () => {
          target.classList.toggle('expand');
          if(target.classList.contains('expand')){
            btn.innerText = 'Hide options...'
          } else {
            btn.innerText = 'Show options...'
          }
        })
      }
    });


  
  }
};